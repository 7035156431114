.DesignItRight {
    color: #FF864E;
    font-size: 62px;
    font-weight: 800;
}

.FirstTime {
    color: #000000;
    font-size: 32px;
    font-weight: 800;
    line-height: 0;
    padding-left: 170px;
}
.DesignItRightRes {
    color: #FF864E;
    font-size: 38px;
    font-weight: 800;
}

.FirstTimeRes {
    color: #000000;
    font-size: 20px;
    font-weight: 800;
    line-height: 0;
    padding-left: 100px;
}
.Content {
    font: normal normal normal 18px/36px Poppins;
    letter-spacing: 0px;
    line-height: 2;
    color: #525252;
    opacity: 1;
    text-align: left;
    
    padding-top: 35px;
}
.ContentRes {
    font: normal normal normal 14px/25px Poppins;
    letter-spacing: 0px;
    color: #525252;
    opacity: 1;
    text-align: justify;
    padding-top: 30px;
  
    /* width: 607px; */
}

.contact-button {
    width: 219px;
    height: 48px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.DesignImg {
    padding-top: 20px;
    width: calc(595px * 0.9);
    height: calc(589px * 0.9);
}
.DesignImgRes{
    width: 329px;
    height: 326px;
}
.Repeat{
    width: 320px;
    height: 42px;
}