.our-approach-heading {
    font: normal normal bold 32px/82px Poppins;
    color: #000000;
    text-align: center;
}

.approach-description {
    font: normal normal normal 17px/27px Poppins;
    color: #6C6B6B;
    text-align:center;
    line-height: 29px;
    width: 947px;
}
.approach-subdescription {
    font: normal normal normal 14px/26px Poppins;
    color: #6C6B6B;
    text-align:left;
    width: 947px;
}
.center{
    text-align: center;
    align-items: center;
    justify-content: center;
}
.approach-descriptionRes {
    font: normal normal normal 14px/26px Poppins;
    color: #6C6B6B;
    text-align: center;
}
.approach-icon {
    width: 42px;
    height: 38px;
    max-width: 100%;
    max-height: 100%;
}

.approach-name {
    text-align: left;
    font: normal normal 600 24px/26px Poppins;
    letter-spacing: 0px;
    color: #000000;
}
.approach-iconRes {
    width: 32px;
    height: 28px;
    max-width: 100%;
    max-height: 100%;
}

.approach-nameRes {
    text-align: left;
    font: normal normal 600 16px Poppins;
    letter-spacing: 0px;
    color: #000000;
}
.approach-caption {
    text-align: left;
    font: normal normal normal 14px/24px Poppins;
    letter-spacing: 0px;
    color: #6C6B6B;
}

.dots {
    width: 324px;
    height: 27px;
    max-width: 100%;
    max-height: 100%;
}

.verticalCenter{
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);  
}