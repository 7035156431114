.animate {
    animation-duration: 5s;
    animation-name: slidein;
    animation-iteration-count: 1;
}

@keyframes slidein {
  0% {
    margin-left: 70%;
  }
 
  100% {
    margin-left: 0%;
  }
}
.our {
    font: normal normal bold 32px/82px Poppins;
    letter-spacing: 0px;
    color: #000000;
    animation-duration: 5s;
    animation-iteration-count: 1;
}

.It {
    color: #FF864E;
    font: normal normal bold 32px/82px Poppins;
    letter-spacing: 0px;
}

.ourRes {
    font: normal normal bold 25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    animation-duration: 5s;
    animation-name: slideout;
    animation-iteration-count: 1;
}

.ItRes {
    color: #FF864E;
    font: normal normal bold 25px Poppins;
    letter-spacing: 0px;
}


.Nearsite-paragraph {
    text-align: center;
    font: normal normal normal 17px/27px Poppins;
    letter-spacing: 0px;
    line-height: 2;
    color: #6C6B6B;
}

.Nearsite-paragraphRes {
    text-align: justify;
    font: normal normal normal 14px/26px Poppins;
    letter-spacing: 0px;
    color: #6C6B6B;
}

.Superior{
    background-color: rgba(255, 228, 205, 1);
    border-radius: 10px;
    padding: 20px 20px 0px 20px;
    width: 618px;
    height: 356px;
    text-align: left;
    font: normal normal bold 32px/82px Poppins;
    letter-spacing: 0px;
    color: #000000;
    padding-left: 30px;
}

.Superiorheading {
    text-align: left;
    font: normal normal bold 32px/82px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.Superiorcontent {
    /* text-align: left; */
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #95785E;
    display: flex;
  /* justify-content: space-between; */
}

.Cost {
    background: #FAF0CF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 20px 20px 0px 20px;
    width: 618px;
    text-align: left;
    font: normal normal bold 32px/82px Poppins;
    letter-spacing: 0px;
    color: #000000;
    padding-left: 30px;

}

.Costheading {
    text-align: left;
    font: normal normal bold 32px/82px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.Costcontent {
    text-align: left;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #95785E;
    display: flex;
}

.SuperiorImg {
    width: 293px;
    height: 182px;
}

.CostImg {
    width: 300px;
    height: 214px;
}



.SuperiorRes{
    background: #FFE4CD 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 15px;
    
}

.SuperiorheadingRes {
    text-align: left;
    font: normal normal bold 20px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.SuperiorcontentRes {
    text-align: justify;
    font: normal normal normal 14px Poppins;
    letter-spacing: 0px;
    color: #95785E;
    display: flex;
    hyphens: none;
  /* justify-content: space-between; */
}

.CostRes {
    background: #FAF0CF 0% 0% no-repeat padding-box;
    border-radius: 15px;
    padding: 15px;

}

.CostheadingRes {
    text-align: left;
    font: normal normal bold 20px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.CostcontentRes {
    text-align: justify;
    font: normal normal normal 14px Poppins;
    letter-spacing: 0px;
    color: #95785E;
    display: flex;
}

.SuperiorImgRes {
    width: 293px;
    height: 182px;
}

.CostImgRes {
    width: 273px;
    height:182px;
}