.landscape-background {
    background-color: #F9F8F4;
    width: 100%;
}

.landscape-image {
    width: calc(429px * .75) ;
    height: calc(612px * .75) ;
    max-width: 100%;
    max-height: 100%;
}

.landscape-heading {
    font: normal normal bold 32px/48px Poppins;
    letter-spacing: 0px;
    color: #000000;
    animation: fadeIn 5s;
}
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
.landscape-headingRes {
    padding-top: 15px;
    font: normal normal bold 25px Poppins;
    color: #000000;
}
.landscape-icon {
    width: 32px;
    height: 33px;
    max-width: 100%;
    max-height: 100%;
}

.landscape-name {
    text-align: left;
    font: normal normal 600 20px/30px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.landscape-imageRes{
    width: calc(370px * .8);
    height: calc(600px * .8);  
}