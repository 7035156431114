.team-part {
	margin-top: 50px;
}

.team-img {
	position: relative;
	overflow: hidden;
    padding-top: 60px;
}

.img-responsive{
    display:block;
    width: auto;
    max-width: 500px;
    height: auto;
}
.img-responsivereS{
    display:block;
    width: auto;
    max-width: 370px;
    height: auto;   
}