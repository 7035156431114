.footer-heading {
    font: normal normal bold 32px/82px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-align: left;
}

.footer-dots {
    width: 880px;
    height: 136px;
    transform: matrix(-1, 0, 0, -1, 0, 0);
    max-width: 100%;
    max-height: 100%;
}
