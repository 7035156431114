.our {
    font: normal normal bold 32px/82px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.It {
    color: #FF864E;
    font: normal normal bold 32px/82px Poppins;
    letter-spacing: 0px;
}

.ourRes {
    font: normal normal bold 25px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.ItRes {
    color: #FF864E;
    font: normal normal bold 25px Poppins;
    letter-spacing: 0px;
}


.Nearsite-paragraph {
    text-align: center;
    font: normal normal normal 17px/27px Poppins;
    line-height: 29px;
    letter-spacing: 0px;
    color: #6C6B6B;
}

.Nearsite-paragraphRes {
    text-align: justify;
    font: normal normal normal 16px/27px Poppins;
    line-height: 29px;
    letter-spacing: 0px;
    color: #6C6B6B;
}

.Superior {
    background: #FFE4CD 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 20px 20px 0px 20px;
    width: 618px;
}

.Superiorheading {
    text-align: left;
    font: normal normal bold 32px/82px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.Superiorcontent {
    /* text-align: left; */
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #95785E;
    display: flex;
    /* justify-content: space-between; */
}

.Cost {
    background: #FAF0CF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 20px 20px 0px 20px;
    width: 618px;

}

.Costheading {
    text-align: left;
    font: normal normal bold 32px/82px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.Costcontent {
    text-align: left;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #95785E;
    display: flex;
}

.SuperiorImg {
    width: 293px;
    height: 182px;
}

.CostImg {
    width: 273px;
    height: 182px;
}



.SuperiorRes {
    background: #FFE4CD 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 15px;

}

.SuperiorheadingRes {
    text-align: left;
    font: normal normal bold 20px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.SuperiorcontentRes {
    text-align: justify;
    font: normal normal normal 14px Poppins;
    letter-spacing: 0px;
    color: #95785E;
    display: flex;
    /* justify-content: space-between; */
}

.CostRes {
    background: #FAF0CF 0% 0% no-repeat padding-box;
    border-radius: 15px;
    padding: 15px;

}

.CostheadingRes {
    text-align: left;
    font: normal normal bold 20px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.CostcontentRes {
    text-align: justify;
    font: normal normal normal 14px Poppins;
    letter-spacing: 0px;
    color: #95785E;
    display: flex;
}

.SuperiorImgRes {
    width: 293px;
    height: 182px;
}

.CostImgRes {
    width: 273px;
    height: 182px;
}

.intro {
    font: normal normal bold 32px/82px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
}

.intro-small {
    text-align: left;
    font: normal normal normal 14px/26px Poppins;
    line-height: 28px;
    letter-spacing: .5px;
    color: #6C6B6B;
}
.Auditr{
    width: 219px;
height: 48px;
background: #000000 0% 0% no-repeat padding-box;
border-radius: 24px;
color: #fff;
cursor:pointer;
text-transform:none ;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
}

.product{
    font: normal normal 600 24px/82px Poppins;
letter-spacing: 0px;
color: #000000;
text-transform: capitalize;
}

.details{
    padding-left: 6px;
    font: normal normal normal 15px/25px Poppins;
letter-spacing: 0px;
color: #000000;
text-align: left;
}