.our-services-heading {
    text-align: center;
    font: normal normal bold 32px/82px Poppins;
    letter-spacing: 0px;
    color: #000000;
}
.our-services-headingRes {
    text-align: left;
    font: normal normal bold 32px Poppins;
    letter-spacing: 0px;
    color: #000000;
}
.service-image {
    width: 42px;
    height: 42px;
    max-width: 100%;
    max-height: 100%;
}

.fade-in {
    animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;
    -moz-animation: fadeIn ease 5s;
    -o-animation: fadeIn ease 5s;
    -ms-animation: fadeIn ease 5s;
  }
  
  
  @keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
.service-name {
    color: #000;
    font: normal normal 600 20px/30px Poppins;
    letter-spacing: 0px;
    text-align: left;
}
.service-nameRes{
    color: #000;
   font-weight: 600;
   font: normal normal 600 15px/25px Poppins;
    letter-spacing: 0px;
    text-align: left;
}
.service-arrow {
    width: 36px;
    height: 36px;
    max-width: 100%;
    max-height: 100%;
}
.dialogContent
{
    font: normal normal normal 14px/24px Poppins;
letter-spacing: 0px;
color: #6C6B6B;
}

.btn{
  color: #FF864E;
  font: normal normal normal 14px/24px Poppins;
  cursor: pointer;
  font-weight: 700;
}
.btn :hover{
  color: #FF864E;
  font: normal normal normal 14px/24px Poppins;
  cursor: pointer;
}