.navigation {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left:0;
    padding-top: 50px;
    padding-left: 40px;
    overflow: hidden;
    padding: 0.5rem 0rem;
    background-color: #fff;
    color: black;
    box-shadow: none;
    z-index: 99;
  }

  .brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
    padding-left: 20px;
  }
  .navigation-menu {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .navigation-menu ul {
    display: flex;
    align-items: center;
    padding: 0;
    font-size:20px;
    font-weight: 500;
  }
  .navigation-menu li {
    list-style-type: none;
    padding-left: 10px;
    padding-right: 10px;
    color: #525252;
    font-size:20px;
    margin: 0 1rem;
  }
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
    font-size:20px;
    color: #525252;
  }

  .navigation-menu li:hover {
    border-left: 1px solid #dd6700;
  }

  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    color: #FF864E;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  .hamburger:hover {
    background-color: #FFE4CD;
  }

  .contact{
    border-radius: 34px;
    background: #FFE4CD 0% 0% no-repeat padding-box;
    border: none;
    height: 54px;
    width: 143px;
    color: #000;
    font: normal normal normal px Poppins;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: 500;
  }
  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      position: absolute;
      top: 60px;
      left: 0;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 77px);
      background-color: white;
      /* border-top: 1px solid black; */
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .navigation-menu li {
      text-align: center;
      margin: 0;
    }
    .navigation-menu li a {
      /* color: black; */
      width: 100%;
      padding: 1.5rem 0;
    }
    .navigation-menu li:hover {
      border-left: 2px solid #dd6700;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu.expanded ul {
      display: block;
      position: fixed;
    }
  }